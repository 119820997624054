<template>
  <master-detail
    :actionBarButtons="actionBarButtons"
    :canDelete="true"
    :canEdit="true"
    :cols="cols"
    :contextOptions="contextmenu"
    :customResource="customResource"
    :descriptionProperty="descriptionProperty"
    :filterQuery="filterQuery"
    formTitle="Timesheet"
    :formWidth="900"
    :hasExportCSV="false"
    :hasExportXLSX="false"
    :hasMonthlyFilter="true"
    :opts="opts"
    :serverPagination="true"
    @onOpenFormDialog="onOpenFormDialog"
    @update:monthlyFilter="getProjects($event)"
  >
    <div class="d-flex">
      <calculation-status-menu class="table-v-action-button mr-3" />
      <async-export-button
        class="table-v-action-button mr-3"
        competencia
        type="timesheets"
      />
    </div>
  </master-detail>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { TipoImportacaoEnum } from "@/core/enums/importacoes";
import { EnquadramentoEnum } from "@/core/enums/projetos";

export default {
  components: {
    "async-export-button": () => import("@/components/async-export-button.vue"),
    "calculation-status-menu": () =>
      import("@/components/calculation-status-menu.vue"),
    "master-detail": () => import("@/components/master-detail.vue"),
  },
  computed: {
    ...mapGetters(["clientId", "intervaloCompetencia"]),
    actionBarButtons: function () {
      return this.userHasAccess("Importacoes.timesheets.timesheet")
        ? [
            {
              text: "Alocação de Horas",
              icon: "mdi-import",
              action: () => {
                this.$router.push({ name: "listagem-importacoes-timesheet", query: { tipo: TipoImportacaoEnum.RH_TIMESHEET }});
              },
            },
        ] : [];
    },
    isClient: function () {
      return this.getClient().isClient;
    },
    cols: function () {
      const columns = [
        {
          key: "matricula",
          name: "Matricula",
          hideInform: true,
        },
        {
          key: "nome",
          name: "Colaborador",
          filterable: true,
          hideInform: true,
        },
        {
          key: "funcionarioId",
          name: "Colaborador",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rules: [{ rule: "required" }],
          rel: { to: "colaboradores", key: "id", name: "label" },
          colSize: 9,
          hideInTable: true,
        },
        {
          key: "projetoTitulo",
          name: "Projeto",
          type: this.$fieldTypes.HTML,
          hideInform: true,
          withoutMargin: true,
        },
        {
          key: "enquadramento",
          name: "Enquadramento",
          align: 0,
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "enquadramento", key: "id", name: "name" },
          hideInform: true,
        },
        {
          key: "data_inicio",
          name: "Início projeto",
          type: this.$fieldTypes.TEXT,
          filterable: true,
          hideInform: true,
        },
        {
          key: "data_fim",
          name: "Fim projeto",
          type: this.$fieldTypes.TEXT,
          filterable: true,
          hideInform: true,
        },
        {
          key: "competencia",
          name: "Competência",
          align: 0,
          type: this.$fieldTypes.MONTH,
          colSize: 3,
          valueChanged: this.getProjects,
        },
        {
          key: "projetoId",
          name: "Projeto",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rules: [{ rule: "required" }],
          filterable: true,
          rel: { to: "projetos", key: "id", name: "titulo" },
          colSize: 12,
          hideInTable: true,
        },
        {
          key: "data",
          name: "Data",
          type: this.$fieldTypes.DATE,
          filterable: true,
          colSize: 3,
          rules: [{ rule: "required" }],
        },
        {
          key: "tipo",
          name: "Período",
          type: this.$fieldTypes.SELECT,
          rel: { to: "tipos", key: "id", name: "name" },
          rules: [{ rule: "required" }],
          editable: !this.desativarCampoTipo,
          colSize: 3,
        },
        {
          key: "tempo",
          name: "Tempo",
          type: this.$fieldTypes.BIGTIME,
          filterable: true,
          rules: [{ rule: "required" }],
          colSize: 3,
        },
        {
          key: "percentual",
          name: "Percentual",
          type: this.$fieldTypes.NUMBER,
          colSize: 3,
        },
        {
          key: "atividade",
          name: "Atividade",
          type: this.$fieldTypes.TEXT,
          colSize: 12,
        },
        {
          key: "jobId",
          name: "ID Importação",
          type: this.$fieldTypes.TEXT,
          filterable: true,
          hideInform: true,
        },
      ];

      if (this.limitarTempo) {
        columns.find(({ key }) => key === "tempo").type = this.$fieldTypes.TIME;
      }

      return columns;
    },
    contextmenu: function () {
      let menu = [
        {
          name: "Cadastro",
          limit: 1,
          icon: "",
          show: this.userHasAccess("Colaboradores.resource.update"),
          cb: ({ funcionarioId }) => {
            const routeData = this.$router.resolve({ name: "edicao-colaborador", params: { colaboradorId: funcionarioId }});
            window.open(routeData.href, "_blank");
          },
        },
        {
          name: "De->Para",
          limit: 1,
          icon: "",
          show: this.userHasAccess("Colaboradores.resource.depara"),
          cb: ({ funcionarioId }) => {
            const routeData = this.$router.resolve({ name: "de-para-colaboradores", params: { colaboradorId: funcionarioId }});
            window.open(routeData.href, "_blank");
          },
        },
        {
          name: "sep",
          // Só mostra separador se houver algum item antes e depois dele
          show: {
            get() {
              return menu.slice(0, 2).some(({ show }) => show) && menu.slice(3).some(({ show }) => show);
            }
          },
        },
        {
          name: "Colaboradores por Projeto",
          limit: 1,
          icon: "",
          show: this.userHasAccess("Timesheet.colaboradoresPorProjetos.index"),
          cb: ({ funcionarioId }) => {
            const routeData = this.$router.resolve({ name: "colaboradores-por-projeto", query: { funcionarioId: funcionarioId }});
            window.open(routeData.href, "_blank");
          },
        },
        {
          name: "Folha de Pagamento",
          limit: 1,
          icon: "",
          show: this.userHasAccess("Colaboradores.folhaDePagamento.index"),
          cb: ({ funcionarioId }) => {
            const routeData = this.$router.resolve({ name: "folha-pagamento-colaborador", params: { colaboradorId: funcionarioId }});
            window.open(routeData.href, "_blank");
          },
        },
        {
          name: "Cálculo do Dispêndio",
          limit: 1,
          icon: "",
          show: !this.isClient && this.userHasAccess("Dispendios.recursosHumanos.index"),
          cb: ({ funcionarioId }) => {
            const routeData = this.$router.resolve({ name: "dispendio-rh", query: { colaboradorId: funcionarioId }});
            window.open(routeData.href, "_blank");
          },
        }
      ];

      return menu.filter(({ show }) => show);
    },
    customResource: function () {      
      const funcionarioId= this.$route.params.colaboradorId;      
      const resource = this.apiResource(
        `/v1/timesheet/${this.clientId}${funcionarioId ? `?funcionarioId=${funcionarioId}` : ''}`
      );
      return {
        ...resource,
        get: (...args) => {
          return resource.get(...args).then((result) => {
            const timesheetsComProjeto = result.data?.map((row) => {
              const {
                jobId,
                competencia,
                enquadramento,
                data_inicio,
                data_fim,
                titulo,
              } = row;
              const dataInicio = moment(data_inicio).startOf("month");
              const dataFim = moment(data_fim).startOf("month");
              const dataCompetencia = moment(`${competencia}-01`);
              const hideProjectMark =
                enquadramento === EnquadramentoEnum.ENQUADRADO &&
                (!data_inicio || dataInicio.isSameOrBefore(dataCompetencia)) &&
                (!data_fim || dataFim.isSameOrAfter(dataCompetencia));
              const projetoTitulo = `
                    <span class="timesheets-project-mark-container">
                      ${titulo}
                      ${
                        hideProjectMark
                          ? ""
                          : '<span class="project-mark"></span>'
                      }
                    </span>
                  `;
              return {
                ...row,
                jobId: jobId == -999 ? "Manual" : jobId,
                enquadramento,
                data_inicio: data_inicio
                  ? this.$options.filters.toDate(data_inicio)
                  : "Indefinido",
                data_fim: data_fim
                  ? this.$options.filters.toDate(data_fim)
                  : "Indefinido",
                projetoTitulo,
              };
            });
            this.timesheets = timesheetsComProjeto || [];
            return timesheetsComProjeto
              ? { ...result, data: timesheetsComProjeto }
              : result;
          });
        },
        save(body, id) {
          body.datas = [{
            data: body.data,
            percentual: body.percentual,
            tempo: body.tempo,
          }];
          delete body.data;
          delete body.percentual;
          delete body.tempo;
          return resource.save(body, id ? `${body.funcionarioId}/${id}` : '');
        },
      };
    },
    filterQuery: function () {
      var filters = [];

      if (this.funcionarioId && this.funcionarioId != "") {
        filters.push(`funcionarioId=${this.funcionarioId}`);
      }

      return filters.length > 0 ? filters.join("&") : null;
    },
    limitarTempo: function () {
      return this.timesheets?.some(({ tipo }) => tipo === 1) || false;
    },
    resourceColaboradores: function () {
      return this.apiResource(`/v1/rh/${this.clientId}/selecao`);
    },
    resourceProjetos: function () {
      return this.apiResource(`/v1/projetos/${this.clientId}/selecao`);
    },
  },
  created: function () {
    const { competenciaIni, competenciaFim, funcionarioId } = this.$route.query;

    if (competenciaIni && competenciaFim) {
      this.$store.commit("setIntervaloCompetencia", [
        competenciaIni,
        competenciaFim,
      ]);
    }

    if (funcionarioId) {
      this.funcionarioId = funcionarioId * 1;
    }

    this.resourceColaboradores.get().then((response) => {
      this.opts.colaboradores = response.colaboradores
        .map(({ matricula, nome, ...rest }) => ({ ...rest, matricula, nome, label: `${matricula ?? 'SEM MATRICULA'} — ${nome}` }))
        .sort(({ nome: nomeA }, { nome: nomeB }) => nomeA.localeCompare(nomeB));
    });
  },
  methods: {
    descriptionProperty: function ({ atividade, competencia }) {
      return `${atividade} — ${this.$options.filters.toMonth(competencia)}`;
    },
    getProjects: function (value) {
      const [competencia] = Array.isArray(value) ? value : [value];
      const ano = competencia?.substring(0, 4);
      if (ano && this.lastProjectYear != ano) {
        this.lastProjectYear = ano;
        const query = `ano=${ano}`;
        this.resourceProjetos.get({ query }).then((response) => {
          this.opts.projetos = response.projetos;
          this.selectedItem && (this.selectedItem.projetoId = null);
        });
      }
    },
    onOpenFormDialog: function (selectedItem) {
      this.selectedItem = selectedItem;
      this.desativarCampoTipo = "id" in selectedItem;
      const { colaboradores } = this.opts;

      if (colaboradores.length === 0) {
        this.resourceColaboradores.get().then((response) => {
          this.opts.colaboradores = response.colaboradores;
        });
      }

      this.getProjects(selectedItem.competencia);
    },
  },
  data: function () {
    return {
      desativarCampoTipo: false,
      funcionarioId: null,
      lastProjectYear: null,
      selectedItem: null,
      timesheets: [],
      opts: {
        projetos: [],
        colaboradores: [],
        tipos: [
          {
            id: 1,
            name: "Diário",
          },
          {
            id: 2,
            name: "Mensal",
          },
        ],
        enquadramento: [
          {
            id: EnquadramentoEnum.REPROVADO,
            name: "Não",
          },
          {
            id: EnquadramentoEnum.NENHUM,
            name: "Não",
          },
          {
            id: EnquadramentoEnum.ENQUADRADO,
            name: "Sim",
          },
        ],
      },
    };
  },
  watch: {
    funcionarioId: function (value) {
      const url = new URL(window.location);

      if (value && value != "") {
        url.searchParams.set("funcionarioId", value);
      } else {
        url.searchParams.delete("funcionarioId");
      }

      window.history.pushState({}, "", url);
    },
    intervaloCompetencia: function (value) {
      const url = new URL(window.location);

      if (Array.isArray(value) && value.length > 1) {
        const [ini, fim] = value;
        url.searchParams.set("competenciaIni", ini);
        url.searchParams.set("competenciaFim", fim);
      } else {
        url.searchParams.delete("competenciaIni");
        url.searchParams.delete("competenciaFim");
      }

      window.history.pushState({}, "", url);
    },
  },
};
</script>

<style lang="scss">
.timesheets-project-mark-container {
  position: relative;

  .project-mark {
    background-color: red;
    border-radius: 50%;
    display: block;
    position: absolute;
    bottom: 10px;
    right: -10px;
    height: 10px;
    width: 10px;
  }
}
</style>
